import jsHttpCookie from 'cookie'
import logger from 'Utils/logger'

const getCookiesFromReqObject = (req) => {
  const cookies = req.headers.cookie
  const cookiesJSON = {}
  if (typeof cookies === 'string') {
    const allCookies = jsHttpCookie.parse(cookies)
    cookiesJSON.authToken = allCookies.authToken
    if (allCookies.loggedUser) {
      try {
        // safe parse with catch
        cookiesJSON.loggedUser = JSON.parse(allCookies.loggedUser)
      } catch (err) {
        logger.log({ decoding_cookies_error: err })
      }
    }
  }
  return cookiesJSON
}

export default getCookiesFromReqObject
