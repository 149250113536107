import axios from 'axios'
import DefaultAPIHandler from 'Utils/DefaultAPIHandler'
import globalsService from 'Services/globalsService'
import GetSchoolHeadersFromHost from 'Utils/GetSchoolHeadersFromHost'
import GetCookiesFromReqObject from 'Utils/GetCookiesFromReqObject'
import GetCookiesFromBrowser from 'Utils/GetCookiesFromBrowser'

// globals is an object of shape
/*
{
  ....,
  school: {...},
  loggedUser: {...},
  authToken: 'xxxx',
}
*/

let localGlobals = null

const noAuthApiAxios = axios.create({
  baseURL: `${process.env.apiServerOrigin}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
})

noAuthApiAxios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
)

noAuthApiAxios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response)
)

const refreshGlobals = (req) => {
  // req is optional and is passed only on server
  // on server use req to get headers, cookies
  let headers = {}
  let cookies = {}

  if (req) {
    // is server
    const { host } = req.headers
    headers = GetSchoolHeadersFromHost(host)
    cookies = GetCookiesFromReqObject(req)
  } else {
    cookies = GetCookiesFromBrowser()
  }

  // safe check for cookies
  if (!cookies) cookies = {}

  return DefaultAPIHandler(
    noAuthApiAxios.get('/essentials/getglobals/', { headers })
  ).then((data) => {
    const globals = { ...data.result, cookies }
    // update local memory cache
    localGlobals = globals
    globalsService.set(globals)
    return globals
  })
}

const GetGlobals = (req = null) => {
  // if exist in cache, resolve but update in background

  if (localGlobals && typeof window !== 'undefined') {
    // do globals caching only on client side
    const cachePromise = new Promise((resolve) => {
      resolve(localGlobals)
    })
    // update cache in background
    refreshGlobals(req).then(
      () => {},
      () => {}
    ) // silent failing
    return cachePromise
  }

  return refreshGlobals(req)
}

export default GetGlobals
