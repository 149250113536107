const GetSubdomainFromHost = (host) => {
  const hostSplit = host.split('.')
  let subdomain = null
  const DEBUG = host && host.indexOf('localhost') !== -1
  if (DEBUG && hostSplit.length === 2) [subdomain] = hostSplit
  else if (hostSplit.length === 3) [subdomain] = hostSplit
  return subdomain
}

export default GetSubdomainFromHost
