import Cookies from 'js-cookie'
import logger from 'Utils/logger'

const GetCookiesFromBrowser = () => {
  const cookiesJSON = {}
  const allCookies = Cookies.get()
  if (!allCookies) return cookiesJSON
  cookiesJSON.authToken = allCookies.authToken
  if (allCookies.loggedUser) {
    try {
      // safe parse with catch
      cookiesJSON.loggedUser = JSON.parse(allCookies.loggedUser)
    } catch (err) {
      logger.log({ decoding_cookies_error: err })
    }
  }
  return cookiesJSON
}

export default GetCookiesFromBrowser
