import ShowAlert from 'Utils/ShowAlert'
import ErrorString from 'Utils/ErrorString'

const DefaultAPIHandler = (reqPromise) => {
  const p = new Promise((resolve, reject) => {
    reqPromise.then(
      (data) => {
        if (data) {
          const { message } = data
          if (message) ShowAlert(message, 'success')
        }
        resolve(data)
      },
      (response) => {
        ShowAlert(ErrorString(response), 'error')
        reject(response)
      }
    )
  })
  return p
}

export default DefaultAPIHandler
