import isCustomDomain from 'Utils/isCustomDomain'
import GetSubdomainFromHost from 'Utils/GetSubdomainFromHost'

const GetSchoolHeadersFromHost = (host) => {
  const headers = {}
  const subdomain = GetSubdomainFromHost(host)
  // get subdomain or http header and add that to api server request
  if (isCustomDomain(host)) headers['YLURN-SCHOOL-DOMAIN'] = host
  else if (subdomain) headers['YLURN-SCHOOL-SLUG'] = subdomain
  return headers
}

export default GetSchoolHeadersFromHost
